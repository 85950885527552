import MicroModal from 'micromodal';  // es6 module

document.addEventListener('DOMContentLoaded', function() {
  // 간편상담 폼
  (($) => {
    const simpleConsultationForm = document.querySelector(".simple-reservation-consultation");
    if (simpleConsultationForm === null) return;

    const submitBtn = simpleConsultationForm.querySelector("button[type='button']");
    const privacyPolicyModalTrigger = simpleConsultationForm.querySelector(".privacy-policy-modal-trigger");
    const agreeCheckbox = simpleConsultationForm.querySelector("input[type='checkbox']");
    
    if (submitBtn === null || privacyPolicyModalTrigger === null || agreeCheckbox === null) return;

    privacyPolicyModalTrigger.addEventListener('click', function() {
        MicroModal.show("privacy-policy-modal", {
          disableScroll: true,
        });
    });

    const handleSubmit = () => {
      const name = simpleConsultationForm.querySelector("input[placeholder='이름(익명가능)']").value;
      const tel = simpleConsultationForm.querySelector("input[placeholder='연락처']").value;
      const agree = agreeCheckbox.checked;

      if (name === '') {
        alert('이름을 입력하세요');
        simpleConsultationForm.querySelector("input[placeholder='이름(익명가능)']").focus();
        return;
      }

      if (tel === '') {
        alert('연락처를 입력하세요');
        simpleConsultationForm.querySelector("input[placeholder='연락처']").focus();
        return;
      }

      if (!agree) {
        alert('간편 상담 신청은 개인정보취급방침에 동의 후 신청 가능합니다.');
        agreeCheckbox.focus();
        return;
      }

      const param = {
        "mode": 'simple_consulting',
        "name": name,
        "tel": tel,
        "url": location.href,
        "agree": agree ? '1' : '0'
      };

      console.log('param - simple-consultation', param);

      $.ajax({
        type: "POST",
        url: "/app/mail.php",
        timeout: 0,
        data: param,
        cache: false,
        dataType: "text",
        error: function(xhr, textStatus, errorThrown) {
          alert("전송에 실패했습니다.");
        },
        success: function(data) {
          console.log('email result', data);
          if (data.indexOf('성공') !== -1) {
            console.log("통신 성공");
            // NAVER SCRIPT
            if (typeof(wcs) !== "undefined") {
              if (!wcs_add) var wcs_add = {};
              wcs_add["wa"] = "s_41a2b1b1795b";
              var _nasa = {};
              _nasa["cnv"] = wcs.cnv("4", "1");
              wcs_do(_nasa);
            }
            alert(name + '님의 상담 신청이 완료되었습니다.');
            window.location.reload();
          } else {
            console.log('data', data);
            alert(data);
          }
        }
      });
    };

    submitBtn.addEventListener('click', handleSubmit);
  })(jQuery);
});
import { locationDetails, isMobile } from "../common";

document.addEventListener('DOMContentLoaded', () => {
    if (isMobile()) return;
    
    const locationPage = document.querySelector('.page-template-location-page-template .location--desktop');
    if (locationPage === null) return;

    // location tab
    (() => {
        const locationTabs = [...(locationPage.querySelectorAll(".location-tab-link") ?? [])];
        const locationPanels = [...(locationPage.querySelectorAll(".location-tab-panel") ?? [])];
        const naverMaps = [...(locationPage.querySelectorAll(".naver-map") ?? [])];
        if (locationTabs.length === 0 || locationPanels.length === 0 || naverMaps.length === 0) return;

        // 탭
        locationTabs.forEach(tab => {
            tab.addEventListener("click", function() {
                const location = this.getAttribute("data-location");

                locationTabs.forEach(node => node.classList.remove("active"));
                locationPanels.forEach(panel => panel.classList.remove("active"));
                naverMaps.forEach(map => map.classList.remove("active"));
                
                tab.classList.add("active");

                locationPanels.forEach(panel => {
                    panel.classList.remove("active");
                    const targetPanelId = `location-tab-${location}`;

                    if (panel.id === targetPanelId) {
                        panel.classList.add("active");
                        activeNaverMap(location);
                        if (window.naver) {
                            loadMapAndDetails(location, locationDetails[location]);
                        } else {
                            console.warn("Naver map not loaded");
                        }
                    }
                });
            });
        });

        function loadMapAndDetails(key, location) {
            const { title, address, mapLink, lat, lng } = location;
            const mapContainerId = `${key}-map`; // 위치 키를 사용하여 ID 구성 (예: 'seoul-map')
            const mapContainer = locationPage.querySelector(`#${mapContainerId}`);
            
            if (mapContainer) {
                mapContainer.innerHTML = ''; // 초기화

                const contentString = `
                    <div class="iw_inner p-15px">
                        <h6 class="text-15px font-600 mb-10px">${title}</h6>
                        <p class="text-13px font-400 mb-14px">${address}</p>
                        <div class="link"><a class="text-yellow-700 font-500 text-14px" href="${mapLink}" target="_blank">네이버 지도 보기</a></div>
                    </div>
                `;
            
                const map = new naver.maps.Map(mapContainer, {
                    center: new naver.maps.LatLng(lat, lng),
                    zoom: 10
                });
                const infowindow = new naver.maps.InfoWindow({
                    content: contentString,
                    maxWidth: 240,
                });
                const marker = new naver.maps.Marker({
                    map: map,
                    position: new naver.maps.LatLng(lat, lng)
                });
                naver.maps.Event.addListener(marker, "click", function() {
                    if (infowindow.getMap()) {
                        infowindow.close();
                    } else {
                        infowindow.open(map, marker);
                    }
                });
                infowindow.open(map, marker);
            } else {
                console.warn(`Map container not found for: ${mapContainerId}`);
            }
        }

        function activeNaverMap(location) {
            const mapContainerId = `${location}-map`; // 위치 키를 사용하여 ID 구성 (예: 'seoul-map')
            const mapContainer = locationPage.querySelector(`#${mapContainerId}`);
            if (!mapContainer) return;

            const parent = mapContainer.parentElement;
            if (!parent) return;
            parent.classList.add('active');
        }
        
        // send click event to data-location="seoul" tab
        const seoulTab = locationPage.querySelector(".location-tab-link[data-location='seoul']");
        if (seoulTab) {
            seoulTab.click();
        }
    })();
});
import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';
import { isMobile } from "../common";

document.addEventListener('DOMContentLoaded', () => {
    if (!isMobile()) return;
    
    const singleLawyerPage = document.querySelector('.single-lawyer--mobile');
    if (singleLawyerPage === null) return;

    (() => {
        const splideContainer = singleLawyerPage.querySelector('.appointment-letter-slider-container > .splide');
        if (!splideContainer) return;
    
        const splide = new Splide(splideContainer, {
          type   : 'loop',
          padding: '0rem',
          arrows: false,
          perPage: 2,
          autoplay: true,
          interval: 4000
        });
        splide.mount();
    })();
});
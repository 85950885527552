document.addEventListener("DOMContentLoaded", function() {
    // Go To Top Btn
    (() => {
        const goToTopBtns = [...(document.querySelectorAll(".go-to-top-btn") ?? [])];
        if (goToTopBtns.length === 0) return;

        goToTopBtns.forEach(goToTopBtn => {
            goToTopBtn.addEventListener("click", function() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        });
    })();
});
import { isMobile } from "./common";

document.addEventListener('DOMContentLoaded', () => {
    // check current size is mobile
    if (!isMobile()) return;

    // 모바일 메뉴
    (() => {
        (($) => {
        const $mobileMenuTrigger = $("#mobile-menu-open-trigger");
        $mobileMenuTrigger.on("click", () => {
            // add 'mobile-menu-opened' class to body;
            $("body").addClass("mobile-menu-opened");
        });

        const $offcanvasCloseTrigger = $("#mobile-menu-close-trigger");
        $offcanvasCloseTrigger.on("click", () => {
            // remove 'mobile-menu-opened' class to body;
            $("body").removeClass("mobile-menu-opened");
        });

        // 외부 클릭시 닫기
        $(".offcanvas-nav-container").click(function (event) {
            // 'offcanvas-inner' 요소를 클릭했는지 확인하기 위해 이벤트 대상을 검사합니다
            if (!$(event.target).closest(".offcanvas-inner").length) {
            // 'offcanvas-inner'가 클릭되지 않았을 때의 동작을 여기에 추가합니다
            $("body").removeClass("mobile-menu-opened");
            }
        });
        })(jQuery);

        (($) => {
        // Include jQuery library
        // '.toggle-sub-menu' 클릭 이벤트를 처리합니다
        $(".offcanvas-nav-container .menu-item.has-sub-menu > a").click(function (
            event
        ) {
            event.preventDefault(); // 기본 동작 방지
            // 클릭된 요소의 부모의 부모 요소인 '.menu-item'에서 '.sub-menu'를 찾아 토글 애니메이션을 적용합니다
            $(this).closest(".menu-item").find(".sub-menu").slideToggle();
    
            $(this).find(".svg-icon-down, .svg-icon-up").toggle();
        });
        })(jQuery);
    })();
});
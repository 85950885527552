import 'vite/modulepreload-polyfill' // 중요 polyfill을 import해주어야 합니다
import '../style.scss'
import MicroModal from 'micromodal';  // es6 module
import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';
import './home-common';
import { locationDetails } from "../common";

document.addEventListener("DOMContentLoaded", function() {
  const homePage = document.querySelector('.home--mobile');
  if (!homePage) return;

  // 성공 사례 탭
  (() => {
    const tabs = [...(homePage.querySelectorAll(".success-case-tab-link") ?? [])];
    const panels = [...(homePage.querySelectorAll(".success-case-tab-panel") ?? [])];
    if (tabs.length === 0 || panels.length === 0) return;

    const panel1 = panels[0];
    const panel2 = panels[1];
    const panel3 = panels[2];
    const panel4 = panels[3];

    // find highest height among all panels and set it to all panels
    setTimeout(() => {
      const maxHeight = Math.max(...[...panels].map(panel => Number(panel.offsetHeight)));
      panels.forEach(panel => {
        panel.style.height = `${maxHeight}px`;
      });
    }, 1000);

    const removeAllActive = () => {
      panels.forEach(panel => panel.classList.remove("active"));
    };

    tabs.forEach(tab => {
      tab.addEventListener("click", function() {
        tabs.forEach(node => node.classList.remove("active"));
        this.classList.add("active");

        const targetId = this.getAttribute("data-target");
        const panel = homePage.querySelector(`#${targetId}`);
        const nth = Number(panel.getAttribute("data-nth"));

        // 활성화된 탭에 따라서 나머지 z-index조정
        if (nth === 1) {
          removeAllActive();
          panel1.classList.add("active");

          panel1.style.zIndex = 4;
          panel2.style.zIndex = 3;
          panel3.style.zIndex = 2;
          panel4.style.zIndex = 1;
        } else if (nth === 2) {
          removeAllActive();
          panel2.classList.add("active");

          panel1.style.zIndex = 3;
          panel2.style.zIndex = 4;
          panel3.style.zIndex = 2;
          panel4.style.zIndex = 1;
        } else if (nth === 3) {
          removeAllActive();
          panel3.classList.add("active");

          panel1.style.zIndex = 2;
          panel2.style.zIndex = 3;
          panel3.style.zIndex = 4;
          panel4.style.zIndex = 1;
        } else if (nth === 4) {
          removeAllActive();
          panel4.classList.add("active");

          panel1.style.zIndex = 1;
          panel2.style.zIndex = 2;
          panel3.style.zIndex = 3;
          panel4.style.zIndex = 4;
        }
      });
    });
  })();

  // 감사 후기
  (() => {
    const splideContainer = homePage.querySelector('.thanks-review .splide');
    if (!splideContainer) return;

    const splide = new Splide(splideContainer, {
      type   : 'loop',
      padding: '3rem',
      arrows: false,
      interval: 4000,
    });
    splide.mount();
  })();

  // youtube video modal
  (() => {
    const youtubeSection = homePage.querySelector('.youtube-videos');
    if (!youtubeSection) return;
    const modalTriggers = [...youtubeSection.querySelectorAll('.modal-trigger')];
    if (modalTriggers.length === 0) return;

    modalTriggers.forEach((trigger, index) => {
      trigger.addEventListener('click', () => {
        const modal = youtubeSection.querySelector(`#youtube_modal_${index}`);
        modal && MicroModal.show(modal.id, {
          disableScroll: true,
          onClose: modal => {
            // modal을 닫을때 iframe을 초기화하여 비디오를 중지합니다
            const iframe = modal.querySelector('iframe');
            if (iframe) iframe.src = iframe.src;
          }
        });
      });
    });
  })();

  // 지도 탭
  // page-template-location-page-template 과는 다릅니다
  (() => {
    const mapTabs = [...(homePage.querySelectorAll(".map-tab-item") ?? [])];
    const mapPanels = [...(homePage.querySelectorAll(".map-tab-panel") ?? [])];

    if (mapTabs.length === 0 || mapPanels.length === 0) return;

    // 탭
    mapTabs.forEach(tab => {
      tab.addEventListener("click", function() {
        const location = this.getAttribute("data-location");
        const targetPanelId = `map-tab-${location}`;

        mapTabs.forEach(node => node.classList.remove("active"));
        mapPanels.forEach(panel => panel.classList.remove("active"));
        
        tab.classList.add("active");

        mapPanels.forEach(panel => {
          panel.classList.remove("active");
          if (panel.id === targetPanelId) {
            panel.classList.add("active");
            loadMapAndDetails(location, locationDetails[location]);
          }
        });
      });
    });

    function loadMapAndDetails(key, location) {
      const { title, address, mapLink, lat, lng } = location;
      const mapContainerId = `${key}-map`; // 위치 키를 사용하여 ID 구성 (예: 'seoul-map')
      const mapContainer = homePage.querySelector(`#${mapContainerId}`);
      
      if (mapContainer) {
        const contentString = `
          <div class="iw_inner p-15px">
            <h6 class="text-15px font-600 mb-10px">${title}</h6>
            <p class="text-13px font-400 mb-14px">${address}</p>
            <div class="link"><a class="text-yellow-700 font-500 text-14px" href="${mapLink}" target="_blank">네이버 지도 보기</a></div>
          </div>
        `;
    
        const map = new naver.maps.Map(mapContainer, {
          center: new naver.maps.LatLng(lat, lng),
          zoom: 10
        });
        const infowindow = new naver.maps.InfoWindow({
          content: contentString,
          maxWidth: 240,
        });
        const marker = new naver.maps.Marker({
          map: map,
          position: new naver.maps.LatLng(lat, lng)
        });
        naver.maps.Event.addListener(marker, "click", function() {
          if (infowindow.getMap()) {
            infowindow.close();
          } else {
            infowindow.open(map, marker);
          }
        });
        infowindow.open(map, marker);
      } else {
        console.warn(`Map container not found for: ${mapContainerId}`);
      }
    }

    // send click event to data-location="seoul" tab
    const seoulTab = homePage.querySelector(".map-tab-item[data-location='seoul']");
    if (seoulTab) {
      seoulTab.click();
    }
  })();
});

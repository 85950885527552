document.addEventListener('DOMContentLoaded', function() {
    function updateDateTime() {
        const now = new Date();
        const options = { month: 'long', day: 'numeric', weekday: 'long' };
        const dateKorean = now.toLocaleDateString('ko-KR', options);
        const timeKorean = `${now.getHours()}시 ${now.getMinutes()}분`;

        document.querySelector('.date').textContent = dateKorean;
        document.querySelector('.time').textContent = timeKorean;
    }

    updateDateTime();  // 초기 로드시 날짜와 시간 설정
    setInterval(updateDateTime, 1000); // 매초마다 업데이트
});
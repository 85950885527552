import MicroModal from 'micromodal';  // es6 module

document.addEventListener('DOMContentLoaded', function() {
  // 간편상담 모달
  (($) => {
    const modal = document.querySelector("#prison-term-calculator-modal");
    const modalTriggers = [...(document.querySelectorAll(".prison-term-calculator-modal-open-trigger") ?? [])];
    const submitBtn = modal.querySelector(".submit-btn");
	const togglePrivacyTrigger = modal.querySelector('.toggle-privacy-content-trigger');
	const privacyContent = modal.querySelector('.privacy-content');

    if (
		modal === null ||
		modalTriggers.length === 0 ||
		submitBtn === null ||
		togglePrivacyTrigger === null ||
		privacyContent === null
	) {
		console.warn("prison-term-calculator-modal.js: modal or modalTriggers or submitBtn is null");
		return;
	}

    modalTriggers.forEach(trigger => {
      trigger.addEventListener("click", function() {
        MicroModal.show("prison-term-calculator-modal", {
            disableScroll: true,
        });
      });
    });

    modal.querySelector('.modal__overlay').addEventListener('click', function(event) {
      const pathContainsClass = (path, className) => path.some(element => element.classList && element.classList.contains(className));
      if (!pathContainsClass(event.composedPath(), 'modal__container')) {
        modal.classList.remove('is-open');
      }
    });

    modal.querySelector('button[data-micromodal-close]').addEventListener('click', function() {
      modal.classList.remove('is-open');
    });

	const updateProgressBar = () => {
		const totalQuestions = 8;
		let answeredQuestions = 0;
	  
		// 각 question을 직접 선택하여 value 확인
		const question1 = modal.querySelector('input[name="question_1"]:checked');
		const question2 = modal.querySelector('input[name="question_2"]:checked');
		const question3 = modal.querySelector('#question_3');
		const question4 = modal.querySelector('input[name="question_4"]:checked');
		const question5 = modal.querySelector('input[name="question_5"]:checked');
		const question6 = modal.querySelector('input[name="question_6"]:checked');
		const question7 = modal.querySelector('#question_7');
		const question8_1 = modal.querySelector('#question_8_1');
		const question8_2 = modal.querySelector('#question_8_2');
	  
		// 각 question의 value 확인하여 count 업데이트
		if (question1) answeredQuestions++;
		if (question2) answeredQuestions++;
		if (question3.value.trim() !== '') answeredQuestions++;
		if (question4) answeredQuestions++;
		if (question5) answeredQuestions++;
		if (question6) answeredQuestions++;
		if (question7.value.trim() !== '') answeredQuestions++;
		if (question8_1.value.trim() !== '' && question8_2.value.trim() !== '') answeredQuestions++;
	  
		const progressText = modal.querySelector('.progress-text');
		const progressBar = modal.querySelector('.progress-bar');
	  
		const progress = (answeredQuestions / totalQuestions) * 100;
		progressText.textContent = `${answeredQuestions} / ${totalQuestions}`;
		progressBar.style.width = `${progress}%`;
	};
	
	// 각 question의 radio 타입 input에 이벤트 리스너 추가
	modal.querySelectorAll('input[name="question_1"]').forEach(input => {
		input.addEventListener('change', updateProgressBar);
	});
	modal.querySelectorAll('input[name="question_2"]').forEach(input => {
		input.addEventListener('change', updateProgressBar);
	});
	modal.querySelector('#question_3').addEventListener('input', updateProgressBar);

	modal.querySelectorAll('input[name="question_4"]').forEach(input => {
		input.addEventListener('change', updateProgressBar);
	});

	modal.querySelectorAll('input[name="question_5"]').forEach(input => {
		input.addEventListener('change', updateProgressBar);
	});

	modal.querySelectorAll('input[name="question_6"]').forEach(input => {
		input.addEventListener('change', updateProgressBar);
	});

	modal.querySelector('#question_7').addEventListener('input', updateProgressBar);
	modal.querySelector('#question_8_1').addEventListener('input', updateProgressBar);
	modal.querySelector('#question_8_2').addEventListener('input', updateProgressBar);

	const isAllQuestionsAnswered = () => {
		const totalQuestions = 8;
		let answeredQuestions = 0;
	  
		// Check if each question is answered
		const question1 = modal.querySelector('input[name="question_1"]:checked');
		const question2 = modal.querySelector('input[name="question_2"]:checked');
		const question3 = modal.querySelector('#question_3');
		const question4 = modal.querySelector('input[name="question_4"]:checked');
		const question5 = modal.querySelector('input[name="question_5"]:checked');
		const question6 = modal.querySelector('input[name="question_6"]:checked');
		const question7 = modal.querySelector('#question_7');
		const question8_1 = modal.querySelector('#question_8_1');
		const question8_2 = modal.querySelector('#question_8_2');
	  
		// Count the number of answered questions
		if (question1) answeredQuestions++;
		if (question2) answeredQuestions++;
		if (question3.value.trim() !== '') answeredQuestions++;
		if (question4) answeredQuestions++;
		if (question5) answeredQuestions++;
		if (question6) answeredQuestions++;
		if (question7.value.trim() !== '') answeredQuestions++;
		if (question8_1.value.trim() !== '' && question8_2.value.trim() !== '') answeredQuestions++;
	  
		// Return true if all questions are answered, false otherwise
		return answeredQuestions === totalQuestions;
	};
	
	const isPrivateInfoAgreed = () => {
		const agreeCheckbox = modal.querySelector('#privacy-agree');
		return agreeCheckbox.checked;
	};

	togglePrivacyTrigger.addEventListener('click', function() {
		privacyContent.classList.toggle('hidden');
	});

    const handleSubmit = () => {
        if(!isAllQuestionsAnswered()){
			alert('모든 질문에 응답해주세요');
			return;
		}
		if (!isPrivateInfoAgreed()) {
			alert('개인정보처리방침에 동의해주세요');
			return;
		}
		
		const param = {
			"mode" : 'survey',
			"answer_1" : $('#question_1').val(),
			"answer_2" : $('#question_2').val(),
			"answer_3" : $('#question_3').val(),
			"answer_4" : $('#question_4').val(),
			"answer_5" : $('#question_5').val(),
			"answer_6" : $('#question_6').val(),
			"answer_7" : $('#question_7').val(),
			"answer_8_name" : $('#question_8_name').val(),
			"answer_8_tel" : $('#question_8_tel').val(),
			"url" : $('#survey_current_url').val(),
			"survey_agree" : $('#survey_agree').val(),
			"question_1" : jQuery('.question_text')[0].innerText,
			"question_2" : jQuery('.question_text')[1].innerText,
			"question_3" : jQuery('.question_text')[2].innerText,
			"question_4" : jQuery('.question_text')[3].innerText,
			"question_5" : jQuery('.question_text')[4].innerText,
			"question_6" : jQuery('.question_text')[5].innerText,
			"question_7" : jQuery('.question_text')[6].innerText,
			"question_8" : jQuery('.question_text')[7].innerText
		};
		
		$.ajax({
			type: "POST",
			url: "/app/mail.php",
			timeout: 0,
			data: param,
			cache: false,
			dataType: "text",
			error: function(xhr, textStatus, errorThrown) {  alert("전송에 실패했습니다."); },
			success: function (data){
				if(data.indexOf('성공') != -1){
					console.log("통신 성공")
					alert($('#question_8_1').val() + '님의 형량예측시스템 신청이 완료되었습니다.');
					window.location.reload();
				} else {
					alert(data);
					console.log(param)
				}
			}
		});
    };

    submitBtn.addEventListener('click', handleSubmit);
  })(jQuery);
});
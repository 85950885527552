import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
    (() => {
        const splideContainer = document.querySelector('#masthead .splide');
        if (!splideContainer) return;

        const splide = new Splide(splideContainer, {
            type: 'loop',
            padding: '0',
            arrows: false,
            autoplay: true,
            perPage: 1,
            direction: 'ttb',
            height: '47px',
            interval: 4000,
        });
        splide.mount();
    })();
});
import MicroModal from 'micromodal';  // es6 module

document.addEventListener('DOMContentLoaded', function() {
  // 간편상담 모달
  (($) => {
    const consultationModal = document.querySelector("#consultation-modal");
    const consultationModalTriggers = [...(document.querySelectorAll(".consultation-modal-trigger") ?? [])];
    const submitBtn = consultationModal.querySelector(".submit-btn");
    const optionBtns = [...(consultationModal.querySelectorAll('.option-btn') ?? [])];
    const privacyPolicyModalTrigger = consultationModal.querySelector(".privacy-policy-modal-trigger");
    const modalOverlay = consultationModal.querySelector('.modal__overlay');
    // const closeBtn = consultationModal.querySelector('button[data-micromodal-close]');

    console.log("consultationModal", consultationModal);
    console.log("consultationModalTriggers", consultationModalTriggers);  
    console.log("submitBtn", submitBtn);
    console.log("optionBtns", optionBtns);
    console.log("privacyPolicyModalTrigger", privacyPolicyModalTrigger);
    console.log("modalOverlay", modalOverlay);

    if (
      consultationModal === null ||
      consultationModalTriggers.length === 0 ||
      submitBtn === null ||
      optionBtns.length === 0 ||
      privacyPolicyModalTrigger === null ||
      modalOverlay === null
    ) {
      console.log('Element not found');
      return;
    }

    let category = null;
    
    privacyPolicyModalTrigger.addEventListener('click', function() {
      MicroModal.show("privacy-policy-modal", {
        disableScroll: true,
      });
    });

    consultationModalTriggers.forEach(trigger => {
      trigger.addEventListener("click", function() {
        category = null;
        MicroModal.show("consultation-modal", {
          disableScroll: true,
        });
      });
    });

    optionBtns.forEach(btn => {
      btn.addEventListener('click', function() {
        const labels = consultationModal.querySelectorAll('.option-label');
        labels.forEach(label => label.classList.remove('active'));
        const parent = btn.parentElement;
        const input = parent.querySelector('input[name="modal_category"]');
        category = input.value;
        parent.classList.add('active');
      });
    });

    modalOverlay.addEventListener('click', function(event) {
      const pathContainsClass = (path, className) => path.some(element => element.classList && element.classList.contains(className));
      if (!pathContainsClass(event.composedPath(), 'modal__container')) {
        consultationModal.classList.remove('is-open');
      }
    });

    const handleSubmit = () => {
      const name = $('#modal_name').val();
      const tel = $('#modal_tel').val();
      const category = $('[name=modal_category]:checked').val();
      const agree = $('#modal_agree')[0].checked;

      console.log('name', name);
      console.log('tel', tel);
      console.log('category', category);
      console.log('agree', agree);

      if (name === '') {
        alert('이름을 입력하세요');
        $('#modal_name').focus();
        return;
      }

      if (tel === '') {
        alert('연락처를 입력하세요');
        $('#modal_tel').focus();
        return;
      }

      if (!category) {
        alert('상담분야를 선택하세요');
        return;
      }

      if (!agree) {
        alert('간편 상담 신청은 개인정보취급방침에 동의 후 신청 가능합니다.');
        $('#modal_agree').focus();
        return;
      }

      const param = {
        "mode": 'consulting',
        "name": name,
        "tel": tel,
        "category": category,
        "url": $('#current_url').val(),
        "agree": agree ? '1' : '0'
      };

      console.log('param', param);

      $.ajax({
        type: "POST",
        url: "/app/mail.php",
        timeout: 0,
        data: param,
        cache: false,
        dataType: "text",
        error: function(xhr, textStatus, errorThrown) {
          alert("전송에 실패했습니다.");
        },
        success: function(data) {
          if (data.indexOf('성공') !== -1) {
            console.log("통신 성공");
            // NAVER SCRIPT
            if (typeof(wcs) !== "undefined") {
              if (!wcs_add) var wcs_add = {};
              wcs_add["wa"] = "s_41a2b1b1795b";
              var _nasa = {};
              _nasa["cnv"] = wcs.cnv("4", "1");
              wcs_do(_nasa);
            }
            alert(name + '님의 상담 신청이 완료되었습니다.');
            window.location.reload();
          } else {
            alert(data);
            console.log(param);
          }
        }
      });
    };

    submitBtn.addEventListener('click', handleSubmit);
  })(jQuery);
});